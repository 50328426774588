import React from "react"
import { Link } from "gatsby"
import { useStaticQuery, graphql } from "gatsby"
import { Grid } from "@mui/material"
import { Button } from "@mui/material"
import AddShoppingCartIcon from "@mui/icons-material/AddShoppingCart"

export const TopErogod = props => {
  const { product } = props
  const data = useStaticQuery(graphql`
    {
      img1: file(relativePath: { eq: "top/erogod1.jpg" }) {
        publicURL
        childImageSharp {
          gatsbyImageData(width: 230, layout: FIXED)
        }
      }
      img2: file(relativePath: { eq: "top/erogod2.jpg" }) {
        publicURL
        childImageSharp {
          gatsbyImageData(width: 230, layout: FIXED)
        }
      }
      img3: file(relativePath: { eq: "top/erogod3.jpg" }) {
        publicURL
        childImageSharp {
          gatsbyImageData(width: 230, layout: FIXED)
        }
      }
      img4: file(relativePath: { eq: "top/erogod5.jpg" }) {
        publicURL
        childImageSharp {
          gatsbyImageData(width: 230, layout: FIXED)
        }
      }
    }
  `)

  return (
    <Grid container spacing={1} id={product.goodsHeaderId}>
      <Grid item xs={6} md={3}>
        <img style={{ width: "100%" }} src={data.img1.publicURL} />
        <div className="text-sm mt-2 font-bold">
          {product.name} {product.variants[0].name}
        </div>
        <div className="text-sm mt-2 text-right">
          {product.variants[0].price.toLocaleString()}円（税込）
        </div>
        <div className="text-center p-2">
          <Link to="/erogod">
            <Button
              variant="contained"
              color="warning"
              startIcon={<AddShoppingCartIcon />}
            >
              詳細
            </Button>
          </Link>
        </div>
      </Grid>

      <Grid item xs={6} md={3}>
        <img style={{ width: "100%" }} src={data.img2.publicURL} />
        <div className="text-sm mt-2 font-bold">
          {product.name} {product.variants[1].name}
        </div>
        <div className="text-sm mt-2 text-right">
          {product.variants[1].price.toLocaleString()}円（税込）
        </div>
        <div className="text-center p-2">
          <Link to="/erogod">
            <Button
              variant="contained"
              color="warning"
              startIcon={<AddShoppingCartIcon />}
            >
              詳細
            </Button>
          </Link>
        </div>
      </Grid>

      <Grid item xs={6} md={3}>
        <img style={{ width: "100%" }} src={data.img3.publicURL} />
        <div className="text-sm mt-2 font-bold">
          {product.name} {product.variants[2].name}
        </div>
        <div className="text-sm mt-2 text-right">
          {product.variants[2].price.toLocaleString()}円（税込）
        </div>
        <div className="text-center p-2">
          <Link to="/erogod">
            <Button
              variant="contained"
              color="warning"
              startIcon={<AddShoppingCartIcon />}
            >
              詳細
            </Button>
          </Link>
        </div>
      </Grid>

    </Grid>
  )
}
export default TopErogod
